import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { deleteStore, getListStores } from "../../redux/actions/storeActions";
import Loader from "../../components/Loader";
import Alert from "../../components/Alert";
import { baseURLFile } from "../../constants";
import Paginate from "../../components/Paginate";
import ConfirmationModal from "../../components/ConfirmationModal";

function StoreScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "1";
  const dispatch = useDispatch();

  const [eventType, setEventType] = useState("");
  const [storeId, setStoreId] = useState("");
  const [confirmOperation, setConfirmOperation] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const listStore = useSelector((state) => state.storeList);
  const { stores, loadingStores, successStores, errorStores, pages } =
    listStore;

  const storeDelete = useSelector((state) => state.deleteStore);
  const { loadingStoreDelete, errorStoreDelete, successStoreDelete } =
    storeDelete;

  const redirect = "/";

  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListStores(page));
    }
  }, [navigate, userInfo, dispatch, page]);

  useEffect(() => {
    if (successStoreDelete) {
      dispatch(getListStores(1));
    }
  }, [successStoreDelete]);

  return (
    <DefaultLayout>
      <div>
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Stores</div>
        </div>
        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default  dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex md:flex-row flex-col justify-between">
            <h4 className=" uppercase font-semibold text-black ">
              STORES MANAGEMENT
            </h4>

            {userInfo && ["SUPERADMIN", "ADMIN"].includes(userInfo.role) ? (
              <div className="flex flex-row">
                <Link
                  to={"/stores/add"}
                  className="rounded bg-primary text-white px-5 py-1 flex flex-row text-sm mx-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 4.5v15m7.5-7.5h-15"
                    />
                  </svg>
                  Add
                </Link>
              </div>
            ) : null}
          </div>

          {/*  */}
          {loadingStores ? (
            <Loader />
          ) : errorStores ? (
            <Alert type="error" message={errorStores} />
          ) : (
            <div className="max-w-full overflow-x-auto mt-3">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2 text-left ">
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max ">
                      N°
                    </th>
                    <th className="min-w-[60px] py-4 px-4 font-bold text-black text-xs w-max ">
                      Store Name
                    </th>
                    <th className="min-w-[30px] py-4 px-4 font-bold text-black text-xs w-max">
                      Store Logo
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-bold text-black text-xs w-max">
                      Employes
                    </th>
                    <th className="py-4 px-4 font-bold text-black text-xs w-max">
                      Operations
                    </th>
                  </tr>
                </thead>
                {/*  */}
                <tbody>
                  {stores?.map((store, index) => (
                    <tr key={index}>
                      <td className="min-w-[30px] border-b border-[#eee] py-2 px-4 ">
                        <p className="text-black  text-xs w-max   flex flex-row  justify-end ">
                          <div>{store.id}</div>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {store.store_name ?? ""}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        {store.store_logo ? (
                          <p className="text-black  text-xs w-max  ">
                            <a href={baseURLFile + store.store_logo}>
                              <img
                                src={baseURLFile + store.store_logo}
                                className="w-10 h-10 "
                                alt={store.store_name}
                              />
                            </a>
                          </p>
                        ) : (
                          <p className="text-black  text-xs w-max  ">---</p>
                        )}
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max  ">
                          {store.employes ?? "0"}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-2 px-4 min-w-[120px]  ">
                        <p className="text-black  text-xs w-max flex flex-row  ">
                          {/* update */}
                          <Link
                            className="mx-1 update-class"
                            to={"/stores/edit/" + store.id}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-primary rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
                              />
                            </svg>
                          </Link>
                          {/* delete */}
                          <button
                            className="mx-1 delete-class"
                            onClick={() => {
                              setEventType("delete");
                              setStoreId(store.id);
                              setConfirmOperation(true);
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0"
                              />
                            </svg>
                          </button>
                        </p>
                      </td>
                    </tr>
                  ))}
                  <tr className="h-11"></tr>
                </tbody>
              </table>
            </div>
          )}
          <div className="">
            <Paginate
              route={"/stores?"}
              search={""}
              page={page}
              pages={pages}
            />
          </div>
        </div>
        <ConfirmationModal
          isOpen={confirmOperation}
          message={
            eventType === "delete"
              ? "Are you sure you want delete this store?"
              : "Are you sure you want to confirm this operation ?"
          }
          onConfirm={async () => {
            if (eventType === "delete" && storeId !== "") {
              setLoadEvent(true);
              await dispatch(deleteStore(storeId)).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setConfirmOperation(false);
            } else {
              setLoadEvent(false);
              setEventType("");
              setConfirmOperation(false);
            }
          }}
          onCancel={() => {
            setConfirmOperation(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
        <div className="grid md:grid-cols-2 w-full container mt-5"></div>
      </div>
    </DefaultLayout>
  );
}

export default StoreScreen;
