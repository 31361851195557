import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import DefaultLayout from "../../layouts/DefaultLayout";
import { getListStores } from "../../redux/actions/storeActions";
import LayoutSection from "../../components/LayoutSection";
import InputModel from "../../components/InputModel";
import { toast } from "react-toastify";
import { addNewOrder } from "../../redux/actions/orderActions";
import { clientList } from "../../redux/actions/clientActions";
import ConfirmationModal from "../../components/ConfirmationModal";
import { productList } from "../../redux/actions/productActions";

function AddOrderScreen() {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  //
  const [orderType, setOrderType] = useState("NORMAL");
  const [orderTypeError, setOrderTypeError] = useState("");

  const [orderFrom, setOrderFrom] = useState("Morocco");
  const [orderFromError, setOrderFromError] = useState("");

  const [specializedNote, setSpecializedNote] = useState("");
  const [specializedNoteError, setSpecializedNoteError] = useState("");

  const [storeSelect, setStoreSelect] = useState("");
  const [storeSelectError, setStoreSelectError] = useState("");

  const [dateSelect, setDateSelect] = useState("");
  const [dateSelectError, setDateSelectError] = useState("");

  const [orderNumber, setOrderNumber] = useState("");
  const [orderNumberError, setOrderNumberError] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalAmountError, setTotalAmountError] = useState("");

  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");

  const [clientPhone, setClientPhone] = useState("");
  const [clientPhoneError, setClientPhoneError] = useState("");

  const [clientEmail, setClientEmail] = useState("");
  const [clientEmailError, setClientEmailError] = useState("");

  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddressError, setShippingAddressError] = useState("");

  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsError, setOrderItemsError] = useState("");

  const [productName, setProductName] = useState("");
  const [productNameError, setProductNameError] = useState("");

  const [productImage, setProductImage] = useState("");
  const [productImagev, setProductImagev] = useState("");
  const [productImageError, setProductImageError] = useState("");

  const [productImageUrl, setProductImageUrl] = useState("");
  const [productImageUrlError, setProductImageUrlError] = useState("");

  const [productQuantity, setProductQuantity] = useState(1);
  const [productQuantityError, setProductQuantityError] = useState("");

  const [productPrice, setProductPrice] = useState(0);
  const [productPriceError, setProductPriceError] = useState("");

  const [productVariation, setProductVariation] = useState("");
  const [productVariationError, setProductVariationError] = useState("");

  const [clientExist, setClientExist] = useState(false);
  const [clientExistError, setClientExistError] = useState(false);

  const [orderClient, setOrderClient] = useState("");
  const [orderClientError, setOrderClientError] = useState("");

  const [productExist, setProductExist] = useState(true);

  const [productSelect, setProductSelect] = useState("");
  const [productSelectError, setProductSelectError] = useState("");
  const [variationList, setVariationList] = useState([]);
  const [variationSelect, setVariationSelect] = useState("");
  const [variationSelectError, setVariationSelectError] = useState("");

  const [fullAddress, setFullAddress] = useState("");
  const [fullAddressError, setFullAddressError] = useState("");

  const [countryAddress, setCountryAddress] = useState("");
  const [countryAddressError, setCountryAddressError] = useState("");

  const [cityAddress, setCityAddress] = useState("");
  const [cityAddressError, setCityAddressError] = useState("");

  const [stateAddress, setStateAddress] = useState("");
  const [stateAddressError, setStateAddressError] = useState("");

  const [zipCodeAddress, setZipCodeAddress] = useState("");
  const [zipCodeAddressError, setZipCodeAddressError] = useState("");

  const [locationXAddress, setLocationXAddress] = useState("");
  const [locationXAddressError, setLocationXAddressError] = useState("");

  const [locationYAddress, setLocationYAddress] = useState("");
  const [locationYAddressError, setLocationYAddressError] = useState("");

  //
  const [isAddOrder, setIsAddOrder] = useState(false);
  const [loadEvent, setLoadEvent] = useState(false);
  const [eventType, setEventType] = useState("");

  //
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo, loading, error } = userLogin;

  const listStore = useSelector((state) => state.storeList);
  const { stores } = listStore;

  const addOrder = useSelector((state) => state.createNewOrder);
  const { loadingOrderAdd, errorOrderAdd, successOrderAdd } = addOrder;

  const listClient = useSelector((state) => state.clientList);
  const { clients } = listClient;

  const listProducts = useSelector((state) => state.productList);
  const { products, loadingProductList, errorProductList } = listProducts;

  const redirect = "/";
  useEffect(() => {
    if (!userInfo) {
      navigate(redirect);
    } else {
      dispatch(getListStores("0"));

      dispatch(clientList("0"));
      dispatch(productList("0"));
    }
  }, [navigate, userInfo, dispatch]);

  useEffect(() => {
    if (successOrderAdd) {
      setOrderType("NORMAL");
      setOrderFrom("Morocco");
      setStoreSelect("");
      setStoreSelectError("");
      setDateSelect("");
      setDateSelectError("");
      setOrderNumber("");
      setOrderNumberError("");
      setTotalAmount("");
      setTotalAmountError("");

      setClientName("");
      setClientNameError("");
      setClientPhone("");
      setClientPhoneError("");
      setClientEmail("");
      setClientEmailError("");
      setShippingAddress("");
      setShippingAddressError("");
      setClientExist(false);
      setClientExistError("");
      setOrderClient("");
      setOrderClientError("");

      setProductExist(true);
      setProductSelect("");
      setProductSelectError("");
      setVariationSelect("");
      setVariationSelectError("");
      setVariationList([]);

      setFullAddress("");
      setFullAddressError("");
      setCountryAddress("");
      setCountryAddressError("");
      setCityAddress("");
      setCityAddressError("");
      setStateAddress("");
      setStateAddressError("");
      setZipCodeAddress("");
      setZipCodeAddressError("");
      setLocationXAddress("");
      setLocationXAddressError("");
      setLocationYAddress("");
      setLocationYAddressError("");
      setSpecializedNote("");
      setSpecializedNoteError("");

      setOrderItems([]);

      setIsAddOrder(false);
      setEventType("");
      setLoadEvent(false);
    }
  }, [successOrderAdd]);

  return (
    <DefaultLayout>
      <div>
        {/* top dash */}
        <div className="flex flex-row text-sm items-center my-1">
          {/* home */}
          <a href="/dashboard">
            <div className="flex flex-row  items-center hover:text-black ">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                />
              </svg>
              <span className="mx-1">Dashboard</span>
            </div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <a href="/orders/">
            <div className="">Orders</div>
          </a>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m8.25 4.5 7.5 7.5-7.5 7.5"
              />
            </svg>
          </span>
          <div className="">Nouveau</div>
        </div>
        {/*  */}

        <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
          <div className="py-2 px-4 md:px-6 xl:px-7.5 flex justify-between">
            <h4 className=" uppercase font-semibold text-black dark:text-white">
              Add new order
            </h4>
          </div>
          {/*  */}
          <div className="flex md:flex-row flex-col ">
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Order Info"
                styles="bg-primary text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Type*"
                    type="select"
                    placeholder=""
                    value={orderType}
                    onChange={(v) => {
                      setOrderType(v.target.value);
                    }}
                    error={orderTypeError}
                    options={[
                      {
                        value: "NORMAL",
                        label: "Normal",
                      },
                      {
                        value: "PROBLEM",
                        label: "Problem",
                      },
                    ]}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="From*"
                    type="select"
                    placeholder=""
                    value={orderFrom}
                    onChange={(v) => {
                      setOrderFrom(v.target.value);
                    }}
                    error={orderFromError}
                    options={[
                      {
                        value: "Morocco",
                        label: "Morocco",
                      },
                      {
                        value: "Other",
                        label: "Other",
                      },
                    ]}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Store*"
                    type="select"
                    placeholder=""
                    value={storeSelect}
                    onChange={(v) => {
                      setStoreSelect(v.target.value);
                    }}
                    error={storeSelectError}
                    options={
                      stores &&
                      stores?.map((store) => ({
                        value: store.id,
                        label: store.store_name,
                      }))
                    }
                  />
                </div>

                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Date*"
                    type="date"
                    placeholder=""
                    value={dateSelect}
                    onChange={(v) => {
                      setDateSelect(v.target.value);
                    }}
                    error={dateSelectError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Order Number*"
                    type="text"
                    placeholder=""
                    value={orderNumber}
                    onChange={(v) => {
                      setOrderNumber(v.target.value);
                    }}
                    error={orderNumberError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Total Amount*"
                    type="number"
                    isPrice={true}
                    placeholder=""
                    value={totalAmount}
                    onChange={(v) => {
                      setTotalAmount(v.target.value);
                    }}
                    error={totalAmountError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Specialized Note"
                    type="textarea"
                    placeholder=""
                    value={specializedNote}
                    onChange={(v) => {
                      setSpecializedNote(v.target.value);
                    }}
                    error={specializedNoteError}
                  />
                </div>
              </LayoutSection>
            </div>
            <div className="md:w-1/2 w-full px-1 py-1">
              <LayoutSection
                title="Customer Info"
                styles="bg-danger text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Client Exist"
                    type="select"
                    placeholder=""
                    value={clientExist}
                    onChange={(v) => {
                      setClientExist(v.target.value === "true");
                      setClientEmail("");
                      setClientName("");
                      setClientPhone("");
                    }}
                    error={""}
                    options={[
                      {
                        value: "false",
                        label: "No",
                      },
                      {
                        value: "true",
                        label: "Yes",
                      },
                    ]}
                  />
                </div>

                {clientExist === true ? (
                  <div className="md:py-2 md:flex ">
                    <InputModel
                      label="Client*"
                      type="searchselect"
                      placeholder=""
                      value={clients?.find(
                        (option) => option.value === orderClient
                      )}
                      onChange={(v) => {
                        setOrderClient(v.value);
                        setClientEmail("");
                        setClientName("");
                        setClientPhone("");

                        if (v.value !== "") {
                          var filter = clients?.find(
                            (client) =>
                              parseInt(v.value) === parseInt(client.id)
                          );
                          if (filter !== null && filter !== undefined) {
                            setClientEmail(filter.email || "");
                            setClientName(filter.full_name || "");
                            setClientPhone(filter.phone || "");
                          }
                        }
                      }}
                      error={orderClientError}
                      options={clients?.map((client) => ({
                        value: client.id,
                        label: client.full_name,
                      }))}
                    />
                  </div>
                ) : null}
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Client Name*"
                    type="text"
                    placeholder=""
                    disabled={clientExist}
                    value={clientName}
                    onChange={(v) => {
                      setClientName(v.target.value);
                    }}
                    error={clientNameError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Client Email*"
                    type="text"
                    disabled={clientExist}
                    placeholder=""
                    value={clientEmail}
                    onChange={(v) => {
                      setClientEmail(v.target.value);
                    }}
                    error={clientEmailError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Client Phone*"
                    type="text"
                    placeholder=""
                    disabled={clientExist}
                    value={clientPhone}
                    onChange={(v) => {
                      setClientPhone(v.target.value);
                    }}
                    error={clientPhoneError}
                  />
                </div>
                {/* <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Shipping Address*"
                    type="text"
                    placeholder=""
                    value={shippingAddress}
                    onChange={(v) => {
                      setShippingAddress(v.target.value);
                    }}
                    error={shippingAddressError}
                  />
                </div> */}
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="w-full px-1 py-1">
              <LayoutSection
                title="Shipping Informations"
                styles="bg-primary text-white font-bold"
              >
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Full Address*"
                    type="text"
                    placeholder=""
                    value={fullAddress}
                    onChange={(v) => {
                      setFullAddress(v.target.value);
                    }}
                    error={fullAddressError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Country Address"
                    type="text"
                    placeholder=""
                    value={countryAddress}
                    onChange={(v) => {
                      setCountryAddress(v.target.value);
                    }}
                    error={countryAddressError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="City Address"
                    type="text"
                    placeholder=""
                    value={cityAddress}
                    onChange={(v) => {
                      setCityAddress(v.target.value);
                    }}
                    error={cityAddressError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="State Address"
                    type="text"
                    placeholder=""
                    value={stateAddress}
                    onChange={(v) => {
                      setStateAddress(v.target.value);
                    }}
                    error={stateAddressError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Zip Code Address"
                    type="text"
                    placeholder=""
                    value={zipCodeAddress}
                    onChange={(v) => {
                      setZipCodeAddress(v.target.value);
                    }}
                    error={zipCodeAddressError}
                  />
                </div>
                <div className="md:py-2 md:flex ">
                  <InputModel
                    label="Location X"
                    type="text"
                    placeholder=""
                    value={locationXAddress}
                    onChange={(v) => {
                      setLocationXAddress(v.target.value);
                    }}
                    error={locationXAddressError}
                  />
                  <InputModel
                    label="Location Y"
                    type="text"
                    placeholder=""
                    value={locationYAddress}
                    onChange={(v) => {
                      setLocationYAddress(v.target.value);
                    }}
                    error={locationYAddressError}
                  />
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="flex md:flex-row flex-col ">
            <div className="w-full px-1 py-1">
              <LayoutSection
                title="Order Items"
                styles="bg-primary text-white font-bold"
              >
                <div className="flex md:flex-row flex-col ">
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Product Exist"
                        type="select"
                        placeholder=""
                        value={productExist}
                        onChange={(v) => {
                          setProductExist(v.target.value === "true");
                          setProductSelect("");
                          setProductSelectError("");
                          setVariationSelect("");
                          setVariationSelectError("");
                          setVariationList([]);
                        }}
                        error={""}
                        options={[
                          {
                            value: "true",
                            label: "Yes",
                          },
                          {
                            value: "false",
                            label: "No",
                          },
                        ]}
                      />
                    </div>
                    {productExist === true ? (
                      <div className="md:py-2 md:flex ">
                        <InputModel
                          label="Product*"
                          type="searchselect"
                          placeholder=""
                          value={products?.find(
                            (option) => option.value === productSelect
                          )}
                          onChange={(v) => {
                            setProductSelect(v.value);
                            if (v.value !== "") {
                              var filter = products?.find(
                                (prod) =>
                                  parseInt(v.value) === parseInt(prod.id)
                              );
                              if (filter !== null && filter !== undefined) {
                                setVariationList(filter.variation_list);
                                setProductName(filter.product_name);
                                setProductImage(filter.product_image);
                                if (filter.is_variation) {
                                  setProductPrice(0);
                                } else {
                                  setProductPrice(filter.product_price);
                                }
                              }
                            }
                          }}
                          error={productSelectError}
                          options={products?.map((prod) => ({
                            value: prod.id,
                            label: prod.product_name,
                          }))}
                        />
                      </div>
                    ) : null}
                    {productExist === true && variationList.length !== 0 ? (
                      <div className="md:py-2 md:flex ">
                        <InputModel
                          label="Variation*"
                          type="searchselect"
                          placeholder=""
                          value={variationList?.find(
                            (option) => option.value === variationSelect
                          )}
                          onChange={(v) => {
                            setVariationSelect(v.value);
                            if (v.value !== "") {
                              var filter = variationList?.find(
                                (variation) =>
                                  parseInt(v.value) === parseInt(variation.id)
                              );
                              if (filter !== null && filter !== undefined) {
                                // setVariationList(filter.variation_list);
                                setProductVariation(filter.variation_price);
                                setProductVariation(filter.variation_name);
                                setProductPrice(filter.variation_price);
                                // setClientEmail(filter.email || "");
                                // setClientName(filter.full_name || "");
                                // setClientPhone(filter.phone || "");
                              }
                            }
                          }}
                          error={variationSelectError}
                          options={variationList?.map((variation) => ({
                            value: variation.id,
                            label:
                              variation.variation_name +
                              " (" +
                              parseFloat(variation.variation_price).toFixed(2) +
                              ")",
                          }))}
                        />
                      </div>
                    ) : null}
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Image"
                        type="file"
                        placeholder=""
                        disabled={productExist}
                        value={productImagev}
                        onChange={(v) => {
                          setProductImage(v.target.files[0]);
                          setProductImagev(v.target.value);
                        }}
                        error={productImageError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Product Image Url"
                        type="text"
                        placeholder=""
                        disabled={productExist}
                        value={productImageUrl}
                        onChange={(v) => {
                          setProductImageUrl(v.target.value);
                        }}
                        error={productImageUrlError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Product Name*"
                        type="text"
                        placeholder=""
                        disabled={productExist}
                        value={productName}
                        onChange={(v) => {
                          setProductName(v.target.value);
                        }}
                        error={productNameError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Product Price*"
                        type="number"
                        isPrice={true}
                        placeholder=""
                        disabled={productExist}
                        value={productPrice}
                        onChange={(v) => {
                          setProductPrice(v.target.value);
                        }}
                        error={productPriceError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Quantity*"
                        type="number"
                        placeholder=""
                        value={productQuantity}
                        onChange={(v) => {
                          setProductQuantity(v.target.value);
                        }}
                        error={productQuantityError}
                      />
                    </div>
                    <div className="md:py-2 md:flex ">
                      <InputModel
                        label="Variation"
                        type="textarea"
                        placeholder=""
                        disabled={productExist}
                        value={productVariation}
                        onChange={(v) => {
                          setProductVariation(v.target.value);
                        }}
                        error={productVariationError}
                      />
                    </div>
                    <div className="md:py-2 flex ">
                      <button
                        onClick={() => {
                          var check = true;
                          setProductImageError("");
                          setProductImageUrlError("");
                          setProductNameError("");
                          setProductPriceError("");
                          setProductQuantityError("");
                          setProductVariationError("");
                          setProductSelectError("");
                          setVariationSelectError("");

                          if (productExist && productSelect === "") {
                            setProductSelectError("This field is required.");
                            check = false;
                          }

                          if (
                            productExist &&
                            variationList.length !== 0 &&
                            variationSelect === ""
                          ) {
                            setVariationSelectError("This field is required.");
                            check = false;
                          }

                          if (
                            !productExist &&
                            productImage === "" &&
                            productImageUrl === ""
                          ) {
                            setProductImageError("This field is required.");
                            check = false;
                          }
                          if (productName === "") {
                            setProductNameError("This field is required.");
                            check = false;
                          }

                          if (productQuantity === "") {
                            setProductQuantityError("This field is required.");
                            check = false;
                          } else {
                            if (
                              isNaN(Number(productQuantity)) ||
                              !Number.isInteger(Number(productQuantity)) ||
                              Number(productQuantity) <= 0
                            ) {
                              setProductQuantityError(
                                "The value of this field is not a positive number."
                              );
                              check = false;
                            }
                          }

                          if (productPrice === "") {
                            setProductPriceError("This field is required.");
                            check = false;
                          } else {
                            if (
                              isNaN(Number(productPrice)) ||
                              Number(productPrice) <= 0
                            ) {
                              setProductPriceError(
                                "The value of this field is not a positive number."
                              );
                              check = false;
                            }
                          }

                          if (check) {
                            const newItem = {
                              product_name: productName,
                              product_image: productExist
                                ? productImagev
                                : productImage,
                              product_image_url: productImageUrl,
                              product_price: productPrice,
                              product_quantity: productQuantity,
                              product_variation: productVariation,
                              product_exist: productExist,
                              product_select: productSelect,
                              variation_select: variationSelect,
                            };

                            setOrderItems([...orderItems, newItem]);

                            console.log(orderItems);
                            setProductImage("");
                            setProductImageUrl("");
                            setProductName("");
                            setProductQuantity(1);
                            setProductPrice(0);
                            setProductVariation("");
                            setProductImagev("");
                          } else {
                            toast.error(
                              "Some fields are mandatory please check"
                            );
                          }
                        }}
                        className="bg-primary text-white text-xs  font-bold px-6 py-2 rounded-full mx-1"
                      >
                        Add Item
                      </button>
                    </div>
                  </div>
                  <div className="md:w-1/2 w-full px-1 py-1">
                    <div className="font-bold ">Items</div>

                    <div>
                      {orderItems?.map((item, index) => (
                        <div
                          key={index}
                          className="flex flex-row my-2 items-center border-b py-3"
                        >
                          <div className="mx-1">
                            <div
                              onClick={() => {
                                // setEventType("delete");
                                // setLoadEvent(false);
                                // setOrderId(order.id);
                                // setIsOpen(true);
                              }}
                              className="mx-1 delete-class cursor-pointer"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="w-5 h-5 bg-danger rounded p-1 text-white text-center text-xs"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="m20.25 7.5-.625 10.632a2.25 2.25 0 0 1-2.247 2.118H6.622a2.25 2.25 0 0 1-2.247-2.118L3.75 7.5m6 4.125 2.25 2.25m0 0 2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125Z"
                                />
                              </svg>
                            </div>
                          </div>
                          {item.product_image ? (
                            <img
                              src={URL.createObjectURL(item.product_image)}
                              alt={item.product_name}
                              className="w-10 h-10 mx-1"
                            />
                          ) : (
                            <img
                              src={item.product_image_url}
                              alt={item.product_name}
                              className="w-10 h-10 mx-1"
                            />
                          )}
                          <div className="mx-1 flex-1">
                            {/* Replace with the appropriate item properties you want to display */}
                            <p className="text-sm font-bold">
                              {item.product_quantity} X {item.product_name}
                            </p>
                            <p className="text-sm">
                              {parseFloat(item.product_price).toFixed(2)}
                            </p>
                            <p className="text-sm">{item.product_variation} </p>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </LayoutSection>
            </div>
          </div>
          <div className="my-2 flex flex-row items-center justify-end">
            <button
              onClick={() => {
                setEventType("cancel");
                setIsAddOrder(true);
              }}
              className=" bg-danger text-white font-bold px-5 py-2 rounded mx-1"
            >
              Cancel
            </button>
            <button
              onClick={async () => {
                var check = true;
                setOrderTypeError("");
                setOrderFromError("");
                setStoreSelectError("");
                setDateSelectError("");
                setOrderNumberError("");
                setTotalAmountError("");

                setClientNameError("");
                setClientPhoneError("");
                setClientEmailError("");
                // setShippingAddressError("");
                setClientExistError("");

                setFullAddressError("");
                setCountryAddressError("");
                setCityAddressError("");
                setStateAddressError("");
                setZipCodeAddressError("");
                setLocationXAddressError("");
                setLocationYAddressError("");

                if (orderType === "") {
                  setOrderTypeError("This field is required.");
                  check = false;
                }
                if (orderFrom === "") {
                  setOrderFromError("This field is required.");
                  check = false;
                }
                if (storeSelect === "") {
                  setStoreSelectError("This field is required.");
                  check = false;
                }
                if (dateSelect === "") {
                  setDateSelectError("This field is required.");
                  check = false;
                }
                if (orderNumber === "") {
                  setOrderNumberError("This field is required.");
                  check = false;
                }
                if (totalAmount === "") {
                  setTotalAmountError("This field is required.");
                  check = false;
                } else {
                  if (isNaN(Number(totalAmount)) || Number(totalAmount) <= 0) {
                    setTotalAmountError(
                      "The value of this field is not a positive number."
                    );
                    check = false;
                  }
                }

                if (clientExist === true && orderClient === "") {
                  setClientExistError("This field is required.");
                  check = false;
                }

                if (orderItems.length === 0) {
                  toast.error("Items Order is required.");
                  check = false;
                }

                if (clientName === "") {
                  setClientNameError("This field is required.");
                  check = false;
                }
                if (clientEmail === "") {
                  setClientEmailError("This field is required.");
                  check = false;
                }
                if (clientPhone === "") {
                  setClientPhoneError("This field is required.");
                  check = false;
                }

                if (fullAddress === "") {
                  setFullAddressError("This field is required.");
                  check = false;
                }

                if (check) {
                  setEventType("add");
                  setIsAddOrder(true);
                } else {
                  toast.error("Some fields are mandatory please check");
                }
              }}
              className=" bg-primary text-white font-bold px-5 py-2 rounded mx-1 flex flex-row justify-center items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              Add
            </button>
          </div>
        </div>
        <ConfirmationModal
          isOpen={isAddOrder}
          message={
            eventType === "cancel"
              ? "Are you sure you want cancel this information ?"
              : "Are you sure you want add this order ?"
          }
          onConfirm={async () => {
            if (eventType === "cancel") {
              setOrderFrom("Morocco");
              setOrderFromError("");
              setOrderType("Normal");
              setOrderTypeError("");

              setStoreSelect("");
              setStoreSelectError("");
              setDateSelect("");
              setDateSelectError("");
              setOrderNumber("");
              setOrderNumberError("");
              setTotalAmount("");
              setTotalAmountError("");

              setClientName("");
              setClientNameError("");
              setClientPhone("");
              setClientPhoneError("");
              setClientEmail("");
              setClientEmailError("");
              setShippingAddress("");
              setShippingAddressError("");
              setClientExist(false);
              setClientExistError("");

              setProductExist(true);
              setProductSelect("");
              setProductSelectError("");
              setVariationSelect("");
              setVariationSelectError("");
              setVariationList([]);

              setFullAddress("");
              setFullAddressError("");
              setCountryAddress("");
              setCountryAddressError("");
              setCityAddress("");
              setCityAddressError("");
              setStateAddress("");
              setStateAddressError("");
              setZipCodeAddress("");
              setZipCodeAddressError("");
              setLocationXAddress("");
              setLocationXAddressError("");
              setLocationYAddress("");
              setLocationYAddressError("");
              setSpecializedNote("");
              setSpecializedNoteError("");

              setIsAddOrder(false);
              setEventType("");
              setLoadEvent(false);
            } else {
              setLoadEvent(true);
              await dispatch(
                addNewOrder({
                  order_type: orderType,
                  order_from: orderFrom,
                  store: storeSelect,
                  order_number: orderNumber,
                  date: dateSelect,
                  client_name: clientName ?? "",
                  client_phone: clientPhone ?? "",
                  client_email: clientEmail ?? "",
                  full_address: fullAddress ?? "",
                  location_x: locationXAddress ?? "",
                  location_y: locationYAddress ?? "",
                  city: cityAddress ?? "",
                  state: stateAddress ?? "",
                  zip_code: zipCodeAddress ?? "",
                  country: countryAddress ?? "",
                  total_amount: totalAmount,
                  items: orderItems,
                  client: clientExist ? orderClient : "",
                  specialized_note: specializedNote ?? "",
                })
              ).then(() => {});
              setLoadEvent(false);
              setEventType("");
              setIsAddOrder(false);
            }
          }}
          onCancel={() => {
            setIsAddOrder(false);
            setEventType("");
            setLoadEvent(false);
          }}
          loadEvent={loadEvent}
        />
      </div>
    </DefaultLayout>
  );
}

export default AddOrderScreen;
